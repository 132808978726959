<template>
    <div>
        <b-table
            hover
            :items="invoices"
            :fields="fields"
            responsive
            class="m-0 text-center"
            select-mode="single"
            selected-variant="danger"
            :per-page="perPage"
            :current-page="currentPage"
        >
            <template v-slot:cell(number)="data">
                <a
                    v-if="data.item.url"
                    :href="data.item.url"
                    download
                    target="_blank"
                    v-b-tooltip.hover
                    :title="$t('ACTION.TIP.OPEN_INVOICE')"
                    class="font-weight-bolder"
                >
                    {{ data.item.fac_numero }}
                </a>
                <span v-else v-b-tooltip.hover.right :title="$t('ACTION.TIP.NO_FILE')" class="font-weight-bolder">
                    {{ data.item.fac_numero }}
                </span>
            </template>
            <template v-slot:cell(date)="data" :data-sorter="data.item.fac_date">
                {{ data.item.fac_date | moment('Do MMMM YYYY') }}
            </template>
            <template v-slot:cell(ht)="data" :data-sorter="data.item.fac_total_ht">
                {{ data.item.fac_total_ht | toCurrency }}
            </template>
            <template v-slot:cell(settlement)="data" :data-sorter="data.item.fac_date_reg">
                {{ data.item.fac_date_reg_prev | moment('Do MMMM YYYY') }}
            </template>
            <template v-slot:cell(statut)="data">
                <span v-if="data.item.fac_date_reg">Réglée</span>
                <span v-else>Non réglée</span>
            </template>
        </b-table>
        <div class="col-xxl-12 d-flex justify-content-center mt-3">
            <div>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="invoices.length"
                    :per-page="perPage"
                    aria-controls="my-table"
                    size="lg"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['isSortDirDesc'],
    data() {
        return {
            dir: true,
            sort: this.sortBy,
            fields: [
                { key: 'number', label: this.$t('INVOICE.NUMBER') },
                { key: 'ht', label: this.$t('INVOICE.HT'), sortable: false },
                { key: 'date', label: this.$t('INVOICE.DATE'), sortable: false },
                { key: 'settlement', label: this.$t('INVOICE.SETTLEMENT_DATE'), sortable: false },
                { key: 'statut', label: this.$t('INVOICE.STATUT'), sortable: false }
            ],
            items: [],
            currentPage: 1,
            perPage: 50
        }
    },
    computed: {
        ...mapGetters({
            invoices: 'listActionsInvoices'
        })
    }
}
</script>
