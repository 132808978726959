<template>
    <div>
        <div class="row">
            <div class="col-xxl-12 mb-2">
                <router-link
                    v-if="prevRoute && prevRoute.name === ('calendar' || 'action.list' || 'action.closed') && prevRoute.name !== null"
                    :to="{
                        name: prevRoute.name === 'calendar' ? 'calendar' : prevRoute.name,
                        params: {
                            month: prevRoute.name === 'calendar' ? actionMonth : '',
                            year: prevRoute.name === 'calendar' ? actionYear : '',
                            type: prevRoute.name === 'action.list' || 'action.closed' ? prevRoute.params.type : ''
                        }
                    }"
                    class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
                >
                    <i class="fas fa-arrow-left mr-2"></i>
                    <span class="d-none d-sm-inline">{{ $t('GENERAL.BACK') }}</span>
                </router-link>
                <router-link
                    v-else-if="prevRoute"
                    :to="{ path: prevRoute.path }"
                    class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
                >
                    <i class="fas fa-arrow-left mr-2"></i>
                    <span class="d-none d-sm-inline">{{ $t('GENERAL.BACK') }}</span>
                </router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12">
                <div class="card card-custom gutter-b">
                    <div class="card-body d-flex rounded bg-light-danger p-12 flex-column flex-md-row flex-lg-column flex-xxl-row">
                        <div
                            class="card card-custom w-auto w-md-400px w-lg-auto w-xxl-500px mr-auto"
                            :class="actionDetails && isClosed && 'bg-light-primary'"
                        >
                            <div class="card-body px-12 py-10">
                                <Skeleton4 v-if="loading" />
                                <template v-else-if="actionDetails">
                                    <h3
                                        class="font-weight-bolder font-size-h2 mb-1"
                                        :class="actionDetails && isClosed ? 'text-primary' : 'text-dark-75'"
                                    >
                                        {{ getTitle() }}
                                    </h3>
                                    <div class="text-primary font-size-h4 mb-9">{{ actionDetails.ht | toCurrency }}</div>
                                    <div class="font-size-sm mb-8">
                                        {{ $t('ACTION.GENERAL.ACTION_STARTS_AT') }}
                                        <strong class="text-primary">{{ actionDetails.deb | moment('Do MMMM YYYY') }}</strong
                                        ><br />
                                        {{ $t('ACTION.GENERAL.AND_ENDS_AT') }}
                                        <strong class="text-primary"> {{ actionDetails.fin | moment('Do MMMM YYYY') }}</strong>
                                    </div>
                                    <div class="d-flex mb-3">
                                        <span class="text-dark-50 flex-root font-weight-bold">{{ $t('ACTION.GENERAL.CUSTOMER') }}</span>
                                        <span class="text-dark flex-root font-weight-bold">{{ actionDetails.cli_nom }}</span>
                                    </div>
                                    <div class="d-flex mb-3">
                                        <span class="text-dark-50 flex-root font-weight-bold">{{
                                            $t('ACTION.GENERAL.INTERNS_NUMBER')
                                        }}</span>
                                        <span class="text-dark flex-root font-weight-bold">{{ totalInterns }}</span>
                                    </div>
                                    <div class="d-flex mb-3">
                                        <span class="text-dark-50 flex-root font-weight-bold">{{ $t('ACTION.GENERAL.DATES_NUMBER') }}</span>
                                        <span class="text-dark flex-root font-weight-bold">{{
                                            actionDetails && actionDetails.planningDates.length
                                        }}</span>
                                    </div>
                                    <div class="d-flex mb-3">
                                        <span class="text-dark-50 flex-root font-weight-bold">{{ $t('ACTION.GENERAL.STATE') }}</span>
                                        <span class="text-dark flex-root font-weight-bold">{{
                                            isClosed
                                                ? $t('ACTION.GENERAL.CLOSED')
                                                : actionDetails.acl_confirme
                                                ? $t('ACTION.GENERAL.IS_VALID')
                                                : $t('ACTION.GENERAL.NOT_CLOSED')
                                        }}</span>
                                    </div>
                                    <div class="d-flex mb-3" v-if="actionDetails.taux_satisfaction">
                                        <span class="text-dark-50 flex-root font-weight-bold">{{
                                            $t('INTERNSHIP_NOTICE.GENERAL.TAUX')
                                        }}</span>
                                        <span class="text-dark flex-root font-weight-bold">
                                            <router-link
                                                :to="{
                                                    name: 'internship-notice.details',
                                                    params: {
                                                        acl_id: parseInt(this.$route.params.id),
                                                        acl_soc: parseInt(this.$route.params.societe)
                                                    }
                                                }"
                                            >
                                                {{ actionDetails.taux_satisfaction }}%
                                            </router-link>
                                        </span>
                                        <!--                                        {{this.$route.params}}-->
                                    </div>
                                </template>
                            </div>
                        </div>
                        <inline-svg
                            v-if="!isMobile()"
                            fill="black"
                            class="m-auto img-responsive px-10"
                            src="/media/svg/illustrations/undraw-2.svg"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xxl-4">
                <div class="card card-custom gutter-b">
                    <div class="card-header border-0 pt-5 pb-5">
                        <h3 class="card-title align-items-start justify-content-center flex-column">
                            <span class="card-label font-weight-bolder">{{ $t('ACTION.DATE.TITLE') }}</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t('ACTION.DATE.DESC') }}</span>
                        </h3>
                    </div>
                    <div class="card-body p-0">
                        <Skeleton3 v-if="loading" />
                        <DateTable
                            :defaultSelectedDate="defaultSelectedDate"
                            v-else-if="actionDetails.planningDates && actionDetails.planningDates.length > 0"
                            :planningDates="actionDetails.planningDates"
                            v-on:dateSelected="dateSelected"
                        />
                        <div v-else-if="!loading && actionDetails.planningDates && actionDetails.planningDates.length === 0" class="px-5">
                            <div class="alert alert-info text-center">
                                {{ $t('ACTION.DATE.NO_DATE') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-8">
                <div class="card card-custom gutter-b">
                    <div class="card-header border-0 pt-5 pb-5">
                        <h3 class="card-title align-items-start justify-content-center flex-column">
                            <span class="card-label font-weight-bolder">{{ $t('ACTION.INTERN.TITLE') }}</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t('ACTION.INTERN.DESC') }}</span>
                        </h3>
                    </div>
                    <div class="card-body p-0">
                        <Skeleton3 v-if="loading" />
                        <InternActionTable
                            v-else-if="
                                actionDetails.planningDates[selectedDate].stagiaires &&
                                    actionDetails.planningDates[selectedDate].stagiaires.length > 0
                            "
                            :interns="actionDetails.planningDates[selectedDate].stagiaires"
                            :sortBy="internsSortBy"
                            :convocation="new Date(actionDetails.fin) >= new Date(dateNow)"
                        />
                        <div v-else-if="!loading && actionDetails.planningDates[selectedDate].stagiaires" class="px-5">
                            <div class="alert alert-info text-center">
                                {{ $t('ACTION.INTERN.NO_INTERN') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="invoice">
            <div class="col-xxl-12">
                <div class="card card-custom gutter-b">
                    <div class="card-header border-0 pt-5 pb-5">
                        <h3 class="card-title align-items-start justify-content-center flex-column">
                            <span class="card-label font-weight-bolder">{{ $t('INVOICE.ACTION.TITLE') }}</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t('INVOICE.ACTION.DESC') }}</span>
                        </h3>
                    </div>
                    <div class="card-body p-0">
                        <Skeleton3 v-if="loading" />
                        <InvoiceTable v-else-if="invoices && invoices.length > 0" />
                        <div v-else-if="!loading && invoices && invoices.length === 0" class="px-5">
                            <div class="alert alert-info text-center">
                                {{ $t('INVOICE.NO_INVOICE') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-12" v-if="loading">
                <Skeleton1 />
            </div>
            <template v-else-if="certificates">
                <div v-if="certificates.convocations_url" class="align-content-stretch" :class="certificates.convocations_url ? 'col-xxl-3' : 'col-xxl-4'">
                    <DocumentDownload :title="$t('ACTION.GENERAL.CONVOCATIONS_URL')" :src="certificates.convocations_url" :subTitle="'Téléchargez les convocations'"/>
                </div>
                <div class="align-content-stretch" :class="certificates.convocations_url ? 'col-xxl-3' : 'col-xxl-4'">
                    <DocumentDownload :title="$t('ACTION.GENERAL.ATTENDANCE_URL')" :src="certificates.time_card_url" :subTitle="actionDetails.planningDates.length > 1 ? 'Téléchargez les feuilles de présence' : 'Téléchargez la feuille de présence'" />
                </div>
                <div class="align-content-stretch" :class="certificates.convocations_url ? 'col-xxl-3' : 'col-xxl-4'">
                    <DocumentDownload :title="$t('ACTION.GENERAL.CERTIFICATE_URL')" :src="certificates.certificate_url" :subTitle="'Téléchargez les attestations de formation groupées.'"/>
                </div>
                <div class="align-content-stretch" :class="certificates.convocations_url ? 'col-xxl-3' : 'col-xxl-4'">
                    <DocumentDownload :title="$t('ACTION.GENERAL.CERTIFICATE_URL_USERS')" :src="certificates.certificate_url_users" :subTitle="'Téléchargez les attestations de formation individuelle.'"/>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module'
import { CERTIFICATES, LIST_ACTIONS_DETAILS } from '@/core/services/store/api-orion/action'
import ApiService from '@/core/services/api.service'
import moment from 'moment'
import DateTable from '@/view/pages/date/widget/Table'
import InternActionTable from '@/view/pages/intern/widget/ActionTable'
import InvoiceTable from '@/view/pages/invoice/widget/Table'
import DocumentDownload from '@/view/content/DocumentDownload'
import Skeleton1 from '@/view/content/skeleton/Skeleton1'
import Skeleton3 from '@/view/content/skeleton/Skeleton3'
import Skeleton4 from '@/view/content/skeleton/Skeleton4'
import { LIST_ACTION_INVOICES } from '@/core/services/store/api-orion/invoice'

export default {
    data() {
        return {
            prevRoute: null,
            loading: true,
            isClosed: false,
            defaultSelectedDate: 0,
            selectedDate: 0,
            datesSortBy: 'pda_date,ase_h_deb,ase_h_fin',
            internsSortBy: 'name',
            invoice: false,
            invoicesSortBy: 'created_at',
            totalInterns: 0,
            dateNow: moment().format('YYYY-MM-DD'),
            actionMonth: null,
            actionYear: null
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    async mounted() {
        this.resetError
        await this.getDetails()
        this.setPageTitle()
        this.totalInterns = this.actionDetails.totalStagiaires
        if (this.actionDetails.fin < this.dateNow) {
            this.isClosed = true
        }
        setTimeout(() => {
            this.loading = false
        }, 200)
        var rights = this.$store.getters.listRights
        rights.forEach(right => {
            if (right.name === 'Factures') {
                this.invoice = true
            }
        })
    },
    methods: {
        getTitle() {
            let reference = this.actionDetails.acl_pro_reference.replace(/<\/?[^>]+>/gi, ' ')
            let libelle = this.actionDetails.acl_pro_libelle.replace(/<\/?[^>]+>/gi, ' ')
            return reference + ' - ' + libelle
        },
        async getDetails() {
            await this.$store.dispatch(LIST_ACTIONS_DETAILS, { params: this.$route.params }).catch(error => {
                if (error.status === 404) {
                    this.$router.push({ name: '404' })
                }
            })
            this.actionMonth = moment(this.actionDetails.deb).month() + 1
            this.actionYear = moment(this.actionDetails.deb).year()
            await this.$store.dispatch(CERTIFICATES, { params: this.$route.params })
            await this.$store.dispatch(LIST_ACTION_INVOICES, { params: this.$route.params })
        },
        setPageTitle() {
            var name = this.getTitle()
            this.$store.dispatch(SET_PAGETITLE, `${this.$t('ACTION.GENERAL.TITLE')} ${name}`)
        },
        async getCertificates() {
            await Promise.all(
                this.dates.data.map(async date => {
                    if (date.interns) {
                        this.totalInterns += date.interns.length
                        await Promise.all(
                            date.interns.map(async (user, index) => {
                                await ApiService.query(`formation/actions/${this.$route.params.id}/interns/${user.id}`, {
                                    params: { perpage: 50 }
                                })
                                    .then(response => (date.interns[index].certificate_url = response.data.data.certificate_url))
                                    .catch(() => (date.interns[index].certificate_url = ''))
                            })
                        )
                    }
                })
            )
        },
        dateSelected(selected) {
            this.selectedDate = selected
        }
    },
    components: {
        DateTable,
        InternActionTable,
        DocumentDownload,
        Skeleton1,
        Skeleton3,
        Skeleton4,
        InvoiceTable
    },
    computed: {
        ...mapMutations({
            resetError: 'resetError'
        }),
        ...mapGetters({
            actionDetails: 'listActionsDetails',
            invoices: 'listActionsInvoices',
            certificates: 'listCertificates'
        }),
        ...mapState({
            error: state => state.actionDetails.error
        })
    }
}
</script>
