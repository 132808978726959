<template>
    <b-table
        striped
        hover
        @row-clicked="clicked"
        select-mode="single"
        selectable
        sort-desc.sync="true"
        :items="items"
        :fields="fields"
        :sort-by.sync="sort"
        responsive
        class="m-0 text-center"
    >
        <template v-slot:cell(name)="data" :data-sorter="`${data.item.lastname} ${data.item.firstname}`">
            <div class="d-flex flex-row justify-content-start ml-2">
                <img :src="data.item.avatar" alt="" class="h-30px align-self-middle rounded" />
                <!--                Enlever le second text-primary quand il y aura les webinaires academy-->
                <span class="ml-2 my-auto font-weight-bold" :class="data.item.plateformeId !== null ? 'text-primary' : 'text-primary'">
                    {{ data.item.name }}
                </span>
            </div>
        </template>
        <template v-slot:cell(certificate_url)="data">
            <a v-if="data.item.sta_attestation" :href="data.item.sta_attestation" class="font-weight-bolder" target="_blank">
                {{ $t('GENERAL.DOWNLOAD') }}
            </a>
            <span v-else v-b-tooltip.hover :title="$t('ACTION.TIP.NO_FILE')" class="font-weight-bolder text-info">
                <i class="fas fa-info-circle text-info"></i> {{ $t('GENERAL.NOT_AVAILABLE_YET') }}
            </span>
        </template>
        <template v-slot:cell(convocation_url)="data">
            <a v-if="data.item.sta_convocation" :href="data.item.sta_convocation" class="font-weight-bolder" target="_blank">
                {{ $t('GENERAL.DOWNLOAD') }}
            </a>
            <span v-else v-b-tooltip.hover :title="$t('ACTION.TIP.NO_FILE')" class="font-weight-bolder text-info">
                <i class="fas fa-info-circle text-info"></i> {{ $t('GENERAL.NOT_AVAILABLE_YET') }}
            </span>
        </template>
    </b-table>
</template>

<script>
import { GET_SOURCE } from '@/core/services/store/api/tableSource'

export default {
    props: ['interns', 'sortBy', 'convocation'],
    data() {
        return {
            sort: this.sortBy,
            fields: [
                { key: 'name', label: this.$t('INTERN.NAME'), sortable: true, class: 'text-left' },
                { key: 'certificate_url', label: this.$t('ACTION.GENERAL.CERTIFICATE_URL'), sortable: false }
            ],
            items: [],
            plateformeId: 'null'
        }
    },
    mounted() {
        this.formatUsers()
        if (this.convocation === true) {
            this.fields.push({ key: 'convocation_url', label: "Convocations", sortable: false })
        }
    },
    methods: {
        async clicked(item) {
            let params = {
                extname: 'Stagiaires',
                extid: item.id
            }
            await this.$store.dispatch(GET_SOURCE, { params }).then(async result => {
                //Si on a un résultat dans la table source
                if (result && result.tableid) {
                    this.plateformeId = result.tableid
                }
            })
            return this.$router.push({
                name: 'intern.view',
                params: { extid: item.id, plateformeid: this.plateformeId }
            })
        },
        formatUsers() {
            this.items = []
            this.interns.map(intern => {
                this.items.push({
                    id: intern.sta_id,
                    name: `${intern.sta_nom} ${intern.sta_prenom}`,
                    plateformeId: intern.sta_plateforme,
                    avatar: intern.avatar,
                    sta_attestation: intern.sta_attestation,
                    sta_convocation: intern.sta_convocation
                })
            })
        }
    },
    watch: {
        interns: function() {
            this.formatUsers()
        }
    }
}
</script>
